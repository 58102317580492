import {
  AppShell,
  Box,
  Center,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import {
  useDebouncedValue,
  useDisclosure,
  useMediaQuery,
} from "@mantine/hooks";
import { Spotlight, SpotlightActionData } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons-react";
import React, { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SpotlightService from "../../services/SpotlightService";
import { HeaderBar } from "./HeaderBar";
import { NavbarNested } from "./NavbarNested";
import { SuspenseLoader } from "./SuspenseLoader";
import dayjs from "dayjs";
import AuthService from "../../services/AuthService";

interface SuspensedViewProps {
  children?: React.ReactNode;
}
const SuspensedView: FC<SuspensedViewProps> = () => (
  <Center>
    <SuspenseLoader />
  </Center>
);
interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: FC<LayoutProps> = () => {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [mobileOpened, mobileHandlers] = useDisclosure(false);
  const [desktopCollapsed, desktopHandlers] = useDisclosure(false);
  const [, setSpotlightEnabled] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounced] = useDebouncedValue(searchQuery, 200);
  const location = useLocation();
  const navigate = useNavigate();
  const [customSpotlightData, setCustomSpotlightData] = React.useState<
    SpotlightActionData[]
  >([]);
  const isMobile = useMediaQuery("(max-width: 48em)");
  const [recentVisitsActions, setRecentVisitsActions] = React.useState<
    SpotlightActionData[]
  >([]);

  useEffect(() => {
    mobileHandlers.close();
  }, [location]);

  const fetchRecentVisits = async () => {
    const recentVisits = await SpotlightService.fetchRecentVisits();
    if (recentVisits) {
      const recentActions = recentVisits.map((visit) => ({
        id: `visit-${visit.id}`,
        label: visit.name,
        description: `Visited ${dayjs(visit.startTime!).format("MMM d, h:mm a")}`,
        group: "Recent Jobs",
        onClick: () => {
          navigate(`/jobs/${visit.id}`);
        },
      }));
      setRecentVisitsActions(recentActions);
    }
  };

  // Effect for search results
  useEffect(() => {
    (async () => {
      const searchResults =
        await SpotlightService.fetchSpotlightActions(debounced);
      const spotlightActions: SpotlightActionData[] = [];
      if (searchResults.subdivisions) {
        searchResults.subdivisions.forEach((subdivision) => {
          spotlightActions.push({
            id: subdivision.id!.toString(),
            label: subdivision.name,
            group: "Subdivisions",
            onClick: () => {
              navigate(`/subdivisions/${subdivision.id}`);
            },
          });
        });
      }
      if (searchResults.jobs) {
        searchResults.jobs.forEach((job) => {
          spotlightActions.push({
            id: job.id!.toString(),
            label: job.name,
            group: "Jobs",
            onClick: () => {
              navigate(`/jobs/${job.id}`);
            },
          });
        });
      }

      // Combine search results with recent visits
      setCustomSpotlightData([...spotlightActions]);
    })();
  }, [debounced]);

  useEffect(() => {
    // Set up token refresh interval
    const interval = setInterval(async () => {
      // Try to refresh token if less than 70 seconds validity remains
      await AuthService.tokens.updateToken();
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Spotlight
        actions={[...recentVisitsActions, ...customSpotlightData]}
        searchProps={{
          leftSection: <IconSearch size={18} />,
          placeholder: "Search...",
        }}
        nothingFound="No results found"
        highlightQuery
        onSpotlightClose={() => {}}
        onQueryChange={async (query) => {
          setSearchQuery(query);
        }}
        limit={10}
        onSpotlightOpen={() => {
          setSpotlightEnabled(true);
          fetchRecentVisits();
        }}
      />
      <AppShell
        layout="alt"
        padding="md"
        header={{ height: 70 }}
        navbar={{
          width:
            !isMobile && !desktopCollapsed
              ? { base: 200, md: 275, lg: 275 }
              : { base: 80 },
          breakpoint: "sm",
          collapsed: { mobile: !mobileOpened },
        }}
      >
        <HeaderBar
          desktopHandlers={desktopHandlers}
          desktopCollapsed={desktopCollapsed}
          toggleColorScheme={toggleColorScheme}
          opened={mobileOpened}
          handlers={mobileHandlers}
          theme={theme}
        />
        <NavbarNested
          isMobile={isMobile || false}
          handlers={mobileHandlers}
          opened={mobileOpened}
          desktopHandlers={desktopHandlers}
          desktopCollapsed={desktopCollapsed}
        />
        <AppShell.Main
          style={
            colorScheme === "dark"
              ? { backgroundColor: theme.colors.dark[9] }
              : { backgroundColor: theme.colors.gray[0] }
          }
        >
          <Box
            p={0}
            // style={{
            //   '@media (max-width: 755px)': {
            //     padding: theme.spacing.xs,
            //   },
            //   '@media (min-width: 755px)': {
            //     padding: theme.spacing.lg,
            //     paddingTop: theme.spacing.sm,
            //   },
            // }}
          >
            <React.Suspense fallback={<SuspensedView />}>
              <Outlet />
            </React.Suspense>
          </Box>
        </AppShell.Main>
      </AppShell>
    </>
  );
};
